<template>
  <div class="">
    <el-button @click="showModal" v-if="conceal">打开弹窗</el-button>
    <!-- 登录弹窗 -->
    <el-dialog title="应试人员登录" :visible.sync="visible" width="530px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="账号：">
          <el-input
            placeholder="请输入账号 test"
            v-model="form.account"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码：">
          <el-input
            placeholder="请输入密码 123456"
            v-model="form.password"
          ></el-input>
        </el-form-item>
        <el-form-item style="text-align: center;">
          <el-button
            type="primary"
            @click="onSubmit"
            style="width: 150px; margin: 10px 0 0-50px;"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 题目列表 -->
    <div>
      <el-button @click="openDialog" v-if="conceal">打开弹窗</el-button>
      <el-dialog
        :visible.sync="dialogVisible"
        title="题目列表"
        :show-close="false"
      >
        <el-table
          :data="subjects"
          border
          style="width: 100%; overflow-y: auto;"
          @row-click="handleRowClick"
          height="400"
          :header-cell-style="{ background: '#1c7bc9', color: '#fff' }"
          :highlight-current-row="true"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column prop="name" label="题目名称"> </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-row justify="center">
            <el-col :span="12">
              <el-button @click="handleCancel">取消</el-button>
            </el-col>
            <el-col :span="12">
              <el-button type="primary" @click="handleOk">确定</el-button>
            </el-col>
          </el-row>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getCourse } from "@/api/course";
import { getListByCourse, findCourseById } from "@/api/course";

export default {
  name: "practiceLogin",
  components: {},
  props: {
    id: {
      type: Number,
    },
    courseData: {
      type: Object,
    },
  },
  data() {
    return {
      conceal: false,
      visible: false, // 控制弹窗的显示与隐藏
      form: {
        account: "test",
        password: "123456",
      },
      // 题目列表
      dialogVisible: false,
      subjects: [],
      selectedRow: null,
      isItemSelected: false, // 是否点击了题目列表中的数据
      // 课程
      courseList: {},
      page: 1,
      // 确定按钮传的数据
      item: {},
    };
  },
  mounted() {
    this.getCourseList();
  },
  methods: {
    // 获取课程
    getCourseList() {
      getCourse({ page: this.page, myself: 1 }).then((res) => {
        console.log("获取课程", res);
        this.courseList = res.data.list;
      });
    },
    // // 获取考试的题目信息
    getListByCourse() {
      // 获取考试的题目
      getListByCourse(this.id).then((res) => {
        console.log("获取考试的题目", res);
        if (res != undefined) {
          this.subjects = res.data;
        }
      });
      // 根据id查询课程
      findCourseById({ id: this.id }).then((res) => {
        if (res.code == 200) {
          this.courseInfo = res.data;
          return res;
        }
      });
    },
    showModal() {
      this.showModal = true;
    },
    onSubmit() {
      this.getListByCourse();
      this.dialogVisible = true;
    },
    // 题目列表弹窗
    openDialog() {
      this.dialogVisible = true;
    },
    // 题目点击对应行
    handleRowClick(row, event, column) {
      this.item = row;
      // 点击题目列表中的数据时，将标志位设置为 true
      this.isItemSelected = true;
    },
    // 题目列表的确定
    handleOk() {
      // 如果点击了列表中的数据
      if (this.isItemSelected) {
        console.log(this.item);
        this.$router.push({
          path: "./loginInterface",
          query: {
            item: this.item,
            courseData: this.courseData,
          },
        });
      } else {
        // 弹出提示请选择题目
        this.$message.warning("请选择题目");
      }
    },
    // 题目列表的取消
    handleCancel() {
      this.visible = false;
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  text-align: center;
}

.dialog-footer .btn {
  margin: 0 40px 30px 40px;
  width: 150px;
}

.el-table_2_column_4 {
  background-color: #23a8f2 !important;
}
</style>
