<template>
  <div class="body">
    <el-carousel height="400px" arrow="never" style="width: 1200px;margin: 0 auto;">
      <el-carousel-item v-for="(item, index) in banners" :key="index" style="background-color: #000">
        <img :src="item.image" style="background-color: #0f4f12; width: 100%; height: 400px" />
        <!-- <div style="background-color: #0f4f12;width: 1920px;height: 400px;"></div> -->
      </el-carousel-item>
    </el-carousel>
    <div class="main">
      <div class="content">
        <!-- 课程列表 -->
        <div style="display: flex">
          <div class="row-name select">
            题库列表
          </div>
        </div>
        <div v-if="courseList.length > 0" class="course-list">
          <div class="course-item" @click="goDetail(item.id)" v-for="(item, index) in courseList" :key="index">
            <div class="course-content">
              <div class="course-img">
                <img :src="item.logo2" alt="" />
              </div>
              <div class="course-content-item">
                <div class="course-name">
                  {{ item.name }}
                </div>
                <div class="course-descr">
                  {{ item.descr }}
                </div>
              </div>
              <div class="practice"><el-button type="primary" round
                  @click.stop="practiceLogin(item.id, index)">模拟考试</el-button>
              </div>
              <div class="el-btn"><el-button type="primary" round>开始学习</el-button></div>
            </div>
          </div>
          <el-pagination style="margin-bottom: 20px; text-align: center" background layout="prev, pager, next"
            :total="total" :hide-on-single-page="true">
          </el-pagination>
        </div>
        <div v-else>
          <el-empty description="暂无课程"></el-empty>
        </div>
      </div>
    </div>
    <footer class="footer">
      <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2021014802号</a>
    </footer>
    <practiceLogin ref="practiceLoginRef" :id="topicId" :courseData="courseData"></practiceLogin>
  </div>
</template>

<script>
import { getCourse } from "@/api/course";
import practiceLogin from "@/views/practiceTest/practiceLogin.vue";

export default {
  components: {
    practiceLogin  // 注册组件
  },
  data() {
    return {
      banners: [
        {
          image:
            "https://www.zhijiao168.com/cloudClassroom/source/img/20211018/a848d501e2684442a30ddbad004f751b.jpg",
        },
      ],
      courseList: [],
      total: 0,
      page: 1,
      topicId: 0,
      courseData: {},
    };
  },
  mounted() {
    this.getCourseList();
  },
  methods: {
    getCourseList() {
      getCourse({ page: this.page, myself: 1 }).then((res) => {
        this.courseList = res.data.list;
        this.total = res.data.total;
      });
    },
    //进入课程详情
    goDetail(id) {
      localStorage.setItem("selectClassId", id);
      this.$router.push({ path: "/selectClass", query: { id: id } });
    },
    // 模拟考试的登录弹窗
    practiceLogin(id, index) {
      console.log(index);
      console.log(this.courseList[index]);
      this.courseData = this.courseList[index]
      this.topicId = id
      this.$refs.practiceLoginRef.visible = true
    }
  },
};
</script>

<style lang="scss" scoped>
.row-name {
  font-size: 20px;
  margin-top: 30px;
  font-weight: 400;
  margin-right: 35px;
  padding-bottom: 4px;
  cursor: pointer;
}

.main {
  width: 1200px;
  height: auto;
  margin: 0 auto;

  .content {
    .content-video-top-text {
      width: 80px;
      font-weight: 400;
      font-size: 20px;
    }

    .line {
      height: 5px;
      width: 70px;
      margin-top: 4px;
      margin-bottom: 10px;
      background-color: #4394ff;
      border-radius: 10px;
    }

    .content-list {
      margin-top: 20px;
      height: 185px;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      padding: 15px 38px 20px 20px;

      .content-list-type {
        font-size: 18px;
        color: #000000;
        font-family: PingFang SC;
      }

      .content-list-all {
        margin-top: 15px;
        display: inline-flex;

        .content-list-image {
          width: 201px;
          height: 142px;
          border-radius: 5px;
          background-color: #4394ff;
        }

        .content-list-content {
          padding-left: 20px;

          .list-title {
            font-size: 18px;
            font-weight: 400;
            color: #000000;
          }

          .list-content {
            width: 940px;
            margin-top: 13px;
            line-height: 22px;
            height: 44px;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .list-date {
            margin-top: 11px;
            height: 22px;
          }

          .list-time {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            height: 22px;
          }

          .button-blue {
            height: 31px;
            background: #4394ff;
            border-radius: 23px;
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            border: none;
            padding: 7px 18px;
          }
        }
      }
    }
  }
}

.line {
  height: 5px;
  width: 60px;
  margin: 4px 0 30px 0;
  background-color: #4394ff;
  border-radius: 5px;
}

.course-item {
  border: 1px solid #eeeeee;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  .course-type-name {
    margin-bottom: 15px;
  }

  .course-content {
    display: flex;
    position: relative;

    .course-content-item {
      margin-left: 20px;

      .course-name {
        margin-bottom: 13px;
        font-size: 18px;
      }

      .course-descr {
        color: #b1b1b1;
      }
    }

    .practice {
      position: absolute;
      bottom: 0;
      right: 130px;
    }

    .el-btn {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.course-list {
  height: auto;
  margin-top: 20px;
}

.course-img {
  width: 224px;
  height: 160px;
  overflow: hidden
}

.course-img img {
  max-width: 300px;
  width: 224px;
  height: 160px;
}

.select {
  border-bottom: 5px solid #4394ff;
}
</style>